import React, { useEffect } from "react";
import { getIsCookieConsent } from "../CookieConsent/CookieConsent";

function LiveAgent() {
  useEffect(() => {
    if (!getIsCookieConsent("gatsby-gdpr-live-agent")) return;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
    (function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
      'https://ecomtrack.ladesk.com/scripts/track.js',
      function(e){ LiveAgent.createButton('83ps6gxi', e); });
    `;
    document.body.appendChild(script);
  }, []);

  return null;
}

export default LiveAgent;

import React from "react";
import CookieConsentBanner from "../CookieConsent/CookieConsent";
import LiveAgent from "./LiveAgent";
import GoogleRecaptcha from "./GoogleRecaptcha";

function CookieCompliance() {
  return (
    <>
      <CookieConsentBanner />
      <LiveAgent />
      <GoogleRecaptcha />
    </>
  );
}

export default CookieCompliance;

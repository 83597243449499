import React, { useEffect } from "react";
import { getIsCookieConsent } from "../CookieConsent/CookieConsent";

function GoogleRecaptcha() {
  useEffect(() => {
    if (!getIsCookieConsent("gatsby-gdpr-google-recaptcha")) return;

    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    document.body.appendChild(script);
  }, []);

  return null;
}

export default GoogleRecaptcha;

import React from "react";
import "./switch.scss";

interface SwitchProps {
  onChangeHandler?: (isToggled: boolean) => void;
}

const Switch = ({ onChangeHandler }: SwitchProps) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={e => onChangeHandler(e.target.checked)}
        defaultChecked={false}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;

import * as React from "react";
import { PageProps } from "gatsby";
import Button from "@/components/Button/button";
import "../../App.scss";
import "@/components/Button/button.scss";
import "@/components/ThemeLifeSpan/ThemeLifeSpan.scss";
import Section from "@/components/Section/Section";
import img404 from "@/img/404.svg";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const NotFound: React.FC<PageProps> = () => (
  <main className="page__404">
    <CookieCompliance />
    <Section className="hero404">
      <img className="hero404__img" src={img404} />
      {/*<img className="hero404__rest" src={img404rest} />*/}

      <div className="hero404__headline">
        <h3>
          It seems like there is no campaign to track. Please proceed to
          homepage.
        </h3>
      </div>

      <Button variant="blue" link="/" text="Go to homepage" />
    </Section>
  </main>
);

export default NotFound;

import React, { useEffect, useState } from "react";
import Switch from "./Switch";
import Cookies from "js-cookie";
import "./cookieConsent.scss";

import arrowDown from "@/img/cookieConsent/arrowDown.svg";
import arrowUp from "@/img/cookieConsent/arrowUp.svg";

enum CookieTypes {
  functionality = "functionality",
  analytics = "analytics",
  marketing = "marketing",
}

interface CookieType {
  functionality: boolean;
  analytics: boolean;
  marketing: boolean;
}

export const cookieTypes = [
  {
    id: CookieTypes.functionality,
    name: "Necessary",
    isAlwaysOn: true,
    description:
      "Necessary cookies are essential for the basic functioning of a website. They enable core features like page navigation, secure areas, and user preferences. These cookies do not gather personally identifiable information and are necessary for the website to work properly.",
  },
  {
    id: CookieTypes.analytics,
    name: "Statistics",
    isAlwaysOn: false,
    description:
      "Statistics cookies help website owners understand how visitors interact with their website by collecting anonymous data. They provide insights into the number of visitors, pages visited, and traffic sources. The information is used to analyze and improve website performance without identifying individual users or tracking their behavior on other sites.",
  },
  {
    id: CookieTypes.marketing,
    name: "Marketing",
    isAlwaysOn: false,
    description:
      "Marketing cookies track visitors across websites to display targeted ads based on their interests. They collect data about browsing habits to personalize advertisements and measure campaign effectiveness. These cookies store information that can identify users and their browsing activity.",
  },
];

const cookieAccessors = [
  {
    cookieName: "gatsby-gdpr-google-tagmanager",
    consentRequired: [CookieTypes.analytics, CookieTypes.marketing],
  },
  {
    cookieName: "gatsby-gdpr-live-agent",
    consentRequired: [CookieTypes.analytics, CookieTypes.marketing],
  },
  {
    cookieName: "gatsby-gdpr-google-recaptcha",
    consentRequired: [CookieTypes.analytics, CookieTypes.marketing],
  },
  {
    cookieName: "gatsby-gdpr-mailchimp",
    consentRequired: [CookieTypes.marketing],
  },
];

export const shouldDisplayCookieBanner = (): boolean => {
  return !cookieAccessors.every(cookie => isCookieSet(cookie.cookieName));
};

export const isCookieSet = (cookieName: string): boolean => {
  const cookie = Cookies.get(cookieName);
  if (cookie === null || cookie === undefined) return false;
  return true;
};

export const deleteCookiesConsent = () => {
  cookieAccessors.forEach(cookie => {
    Cookies.remove(cookie.cookieName);
  });
  location.reload();
};

export const getIsCookieConsent = (cookieName): boolean => {
  return Cookies.get(cookieName) === "true";
};

const CookieConsentBanner = () => {
  const [isShowBanner, setIsShowBanner] = useState(false);
  const [isShowPreferences, setIsShowPreferences] = useState(false);
  const [showCookieDescription, setShowCookieDescription] =
    useState<CookieType>({
      functionality: false,
      analytics: false,
      marketing: false,
    });
  const [customCookiesConsent, setCustomCookiesConsent] = useState<CookieType>({
    functionality: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    setIsShowBanner(shouldDisplayCookieBanner());
  }, []);

  const showPreferences = () => {
    setIsShowPreferences(!isShowPreferences);
  };
  const toggleShowCookieDescription = (type: CookieTypes) => {
    setShowCookieDescription({
      ...showCookieDescription,
      [type]: !showCookieDescription[type],
    });
  };

  const setCustomCookies = (type: CookieTypes, consent: boolean) => {
    setCustomCookiesConsent({
      ...customCookiesConsent,
      [type]: consent,
    });
  };

  const submitCustomCookiesConsent = () => {
    cookieAccessors.forEach(cookie => {
      const canUseCookie = cookie.consentRequired.every(
        consent => customCookiesConsent[consent],
      );
      Cookies.set(cookie.cookieName, canUseCookie, { expires: 365 });
    });
    location.reload();
  };

  const submitCookiesConsent = (isAccepted: boolean = false) => {
    cookieAccessors.forEach(cookie => {
      Cookies.set(cookie.cookieName, isAccepted, { expires: 365 });
    });
    location.reload();
  };

  if (!isShowBanner) return <></>;

  return (
    <div className="banner">
      <h4>Cookie settings</h4>
      <p style={{ margin: 0 }}>
        We use cookies to enhance your browsing experience and provide
        personalized content on our website. By continuing to use our website,
        you agree to the use of cookies as described in this Cookie Policy.
      </p>

      {isShowPreferences && (
        <div className="items-container">
          {cookieTypes.map(cookie => (
            <div className="item-container" key={cookie.name}>
              <div className="item-header">
                <p>{cookie.name}</p>
                <span className="switch-container">
                  {!cookie.isAlwaysOn && (
                    <Switch
                      onChangeHandler={checked =>
                        setCustomCookies(cookie.id, checked)
                      }
                    />
                  )}
                  <img
                    onClick={() => toggleShowCookieDescription(cookie.id)}
                    className="arrow"
                    src={`${
                      showCookieDescription[cookie.id] ? arrowDown : arrowUp
                    }`}
                  />
                </span>
              </div>
              {showCookieDescription[cookie.id] && (
                <p className="item-body">{cookie.description}</p>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="options-container">
        <button
          onClick={() => submitCookiesConsent(true)}
          className="option active"
        >
          Accept all
        </button>
        <button onClick={() => submitCookiesConsent(false)} className="option">
          Reject all
        </button>
        <button
          onClick={() =>
            isShowPreferences ? submitCustomCookiesConsent() : showPreferences()
          }
          className="option"
        >
          {isShowPreferences ? "Save" : "Customize"}
        </button>
      </div>
      <a className="policy-link" href="/privacy-policy">
        Privacy policy
      </a>
    </div>
  );
};

export default CookieConsentBanner;
